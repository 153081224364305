import { VNode } from "preact";
import { DATA_ANCESTOR, DATA_CURRENT } from "../constants.ts";

export const enum UrlMatchKind {
  None,
  Ancestor,
  Current,
}

export function matchesUrl(current: string, needle: string): UrlMatchKind {
  let href = new URL(needle, "http://localhost").pathname;
  if (href !== "/" && href.endsWith("/")) {
    href = href.slice(0, -1);
  }

  if (current !== "/" && current.endsWith("/")) {
    current = current.slice(0, -1);
  }

  if (current === href) {
    return UrlMatchKind.Current;
  } else if (current.startsWith(href + "/") || href === "/") {
    return UrlMatchKind.Ancestor;
  }

  return UrlMatchKind.None;
}

/**
 * Mark active or ancestor link
 * Note: This function is used both on the server and the client
 */
export function setActiveUrl(vnode: VNode, pathname: string): void {
  const props = vnode.props as Record<string, unknown>;
  const hrefProp = props.href;
  if (typeof hrefProp === "string" && hrefProp.startsWith("/")) {
    const match = matchesUrl(pathname, hrefProp);
    if (match === UrlMatchKind.Current) {
      props[DATA_CURRENT] = "true";
      props["aria-current"] = "page";
    } else if (match === UrlMatchKind.Ancestor) {
      props[DATA_ANCESTOR] = "true";
      props["aria-current"] = "true";
    }
  }
}

// denoCacheMetadata={"headers":{"content-type":"application/typescript; charset=utf-8","x-amz-version-id":"bG05Wb7RQ0MPFEXPWn3wzaO0UexUwZci","x-frame-options":"DENY","strict-transport-security":"max-age=63072000; includeSubDomains; preload","accept-ranges":"bytes","cross-origin-resource-policy":"same-origin","server-timing":"fetchSource;dur=8","x-amz-replication-status":"COMPLETED","cross-origin-opener-policy":"same-origin","access-control-allow-origin":"*","content-length":"1290","via":"http/2 edgeproxy-h","server":"deno/gcp-us-east4","vary":"Accept-Encoding, Origin","x-amz-cf-id":"8OeYB9WxJd2-5JszNoGcZRZiaqzCN8FxOiaYmmHiscUr2mucRWS-vQ==","cache-control":"public, max-age=31536000, immutable","x-amz-cf-pop":"IAD61-P2","x-cache":"Hit from cloudfront","age":"200729","etag":"\"8c5875609ecffdbf761741a83dc583e1\"","cross-origin-embedder-policy":"same-origin","x-amz-server-side-encryption":"AES256","date":"Thu, 06 Mar 2025 07:09:40 GMT","last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","x-content-type-options":"nosniff","referrer-policy":"strict-origin-when-cross-origin","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox"},"url":"https://deno.land/x/fresh@1.6.8/src/runtime/active_url.ts","time":1741445708}