export const PARTIAL_SEARCH_PARAM = "fresh-partial";
export const PARTIAL_ATTR = "f-partial";
export const LOADING_ATTR = "f-loading";
export const CLIENT_NAV_ATTR = "f-client-nav";
export const DATA_KEY_ATTR = "data-fresh-key";
export const DATA_CURRENT = "data-current";
export const DATA_ANCESTOR = "data-ancestor";

export const enum PartialMode {
  REPLACE,
  APPEND,
  PREPEND,
}

// denoCacheMetadata={"headers":{"last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","x-amz-cf-id":"d2cotk056kmdNIyMGLTuDZyIcxh-JENASePW-Y2-Mhm1JYYF-B5DpQ==","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","cross-origin-resource-policy":"same-origin","x-cache":"Hit from cloudfront","etag":"\"cbf7306b660163352728761242054293\"","server-timing":"fetchSource;dur=8","via":"http/2 edgeproxy-h","access-control-allow-origin":"*","x-content-type-options":"nosniff","vary":"Accept-Encoding, Origin","x-amz-server-side-encryption":"AES256","cross-origin-opener-policy":"same-origin","x-amz-replication-status":"COMPLETED","age":"201398","content-length":"386","x-amz-version-id":"706Vuf2AgHYe7CCucNHbXULbEOSyPiKM","accept-ranges":"bytes","cache-control":"public, max-age=31536000, immutable","x-frame-options":"DENY","cross-origin-embedder-policy":"same-origin","date":"Thu, 06 Mar 2025 06:58:31 GMT","referrer-policy":"strict-origin-when-cross-origin","content-type":"application/typescript; charset=utf-8","server":"deno/gcp-us-east4","strict-transport-security":"max-age=63072000; includeSubDomains; preload","x-amz-cf-pop":"IAD61-P2"},"url":"https://deno.land/x/fresh@1.6.8/src/constants.ts","time":1741445708}